<template>
    <div class="w-full h-full bg-codesample md:shadow-none">
        <div class="h-12 bg-codesample-dark flex flex-row justify-between items-center">
            <div class="relative flex items-center cursor-pointer">
                <select
                    class="block appearance-none cursor-pointer text-sm pl-2 pr-6 bg-codesample-dark text-gray-500 py-1 rounded leading-tight focus:outline-none focus:bg-codesample-dark"
                    v-model="activeRuntime"
                    @change="onChangeRuntime"
                    :disabled="showTerminal">
                    <option
                        v-for="(runtime, idx) in runtimes"
                        :key="idx"
                        :value="runtime">
                        {{ runtime | formatRuntime }}
                    </option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pt-1 text-gray-500">
                    <svg class="h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor"><path d="M7 7l3-3 3 3m0 6l-3 3-3-3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /></svg>
                </div>
            </div>
            <div class="flex flex-row justify-end items-center">
                <template v-if="!showTerminal">
                    <span
                        class="text-gray-500 cursor-pointer transition duration-200 ease-in-out hover:translate-x-2px hover:text-gray-100"
                        aria-label="Click to copy"
                        data-balloon-pos="left"
                        @click="copyActiveCodeSample">
                        <span class="pointer-events-none flex flex-row items-center tracking-wide text-sm px-2">
                            <copySvg class="h-4 mr-1 fill-current" />Copy
                        </span>
                    </span>

                    <span
                        v-if="terminalEnabled"
                        type="button"
                        class="text-gray-500 cursor-pointer transition duration-200 ease-in-out hover:translate-x-2px hover:text-gray-100"
                        aria-label="Click to run the code"
                        data-balloon-pos="left"
                        @click="showTerminal = true">
                        <span
                            class="pointer-events-none flex flex-row items-center tracking-wide text-sm px-2">
                            <runSvg class="h-4 mr-1 fill-current" />Try It!
                        </span>
                    </span>

                </template>

                <template v-else>
                    <span
                        type="button"
                        class="text-gray-500 cursor-pointer transition duration-200 ease-in-out hover:translate-x-2px hover:text-gray-100"
                        aria-label="Click to close terminal"
                        data-balloon-pos="left"
                        @click="showTerminal = false">
                        <span
                            class="pointer-events-none flex flex-row items-center tracking-wide text-sm px-2">
                            <closeSvg class="h-4 mr-1 fill-current" />Close
                        </span>
                    </span>
                </template>
            </div>
        </div>
        <div class="absolute top-12 bottom-0 left-0 right-0 flex flex-col">
            <template v-if="showTerminal">
                <Terminal :baseCommand="activeTerminalCommand" :onExit="() => { showTerminal = false; }" />
            </template>
            <template v-else>
                <div
                    :ref="'codeSampleContent'"
                    class="code-sample-content w-full flex-1 bg-codesample overflow-y-auto">
                    <VueMarkdown
                        v-if="activeSample"
                        :source="activeSample.content"
                        @rendered="onMarkdownRendered" />
                </div>
                <div class="console-resize-handler"></div>
                <CodeResponseSamples
                    v-if="responseSamples.length > 0"
                    :responseSamples="responseSamples"
                />
            </template>

        </div>
    </div>
</template>

<script>
import {
    mapState,
    mapMutations,
} from 'vuex';

import VueMarkdown from 'vue-markdown';

import hljs from 'highlight.js/lib/core';

import bash from 'highlight.js/lib/languages/bash';
import php from 'highlight.js/lib/languages/php';
import java from 'highlight.js/lib/languages/java';
import javascript from 'highlight.js/lib/languages/javascript';
import python from 'highlight.js/lib/languages/python';
import ruby from 'highlight.js/lib/languages/ruby';

hljs.registerLanguage('bash', bash);
hljs.registerLanguage('php', php);
hljs.registerLanguage('java', java);
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('python', python);
hljs.registerLanguage('ruby', ruby);

// FIXME::ckarundu Explore escaping html instead
hljs.configure({ ignoreUnescapedHTML: true });

export default {
    name: 'DocsCodeSamples',

    props: {
        codeSamples: {
            type: Array,
            required: true,
        },
        responseSamples: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            showTerminal: false,
            activeRuntime: null,
        };
    },

    computed: {
        ...mapState([
            'isPreview',
        ]),

        ...mapState('docs', [
            'defaultRuntime',
            'selectedRuntime',
        ]),

        runtimes() {
            return this.codeSamples.map((sample) => sample.runtime);
        },

        activeSample() {
            const sample = this.codeSamples.find((s) => s.runtime === this.activeRuntime);
            return sample;
        },

        activeTerminalCommand() {
            let cmd = this.activeSample.content.trim().replace('```bash', '').replace('```', '').trim();
            cmd = cmd.split('\\').map((i) => i.trim()).join(' ');
            return cmd;
        },

        terminalEnabled() {
            return this.isPreview && this.activeRuntime === this.defaultRuntime;
        },
    },

    created() {
        this.setActiveRuntime();
    },

    watch: {
        codeSamples() {
            this.setActiveRuntime();
        },
    },

    methods: {
        ...mapMutations('docs', [
            'setSelectedRuntime',
        ]),

        setActiveRuntime() {
            const runtime = this.selectedRuntime || this.defaultRuntime;
            const runtimeInAvailableRuntimes = this.runtimes.some((r) => r === runtime);

            if (runtimeInAvailableRuntimes) {
                this.activeRuntime = runtime;
            } else {
                // eslint-disable-next-line
                this.activeRuntime = this.runtimes[0];
            }
        },

        onChangeRuntime() {
            this.setSelectedRuntime(this.activeRuntime);
        },

        highlightCodeSamples() {
            this.$refs.codeSampleContent.querySelectorAll('pre code').forEach((block) => {
                hljs.highlightElement(block);
            });
        },

        onMarkdownRendered() {
            this.$nextTick(() => {
                this.highlightCodeSamples();
                this.$refs.codeSampleContent.scrollTop = 0;
            });
        },

        copyActiveCodeSample(evt) {
            const selection = window.getSelection();
            const range = document.createRange();
            const codeEl = this.$refs.codeSampleContent.querySelector('pre code');
            range.selectNodeContents(codeEl);
            selection.removeAllRanges();
            selection.addRange(range);

            try {
                document.execCommand('copy');
                selection.removeAllRanges();

                const el = evt.srcElement;
                const originalLabel = el.getAttribute('aria-label');
                el.setAttribute('data-balloon-visible', 'true');
                el.setAttribute('aria-label', 'Copied!');

                setTimeout(() => {
                    el.removeAttribute('data-balloon-visible');
                }, 1200);
                setTimeout(() => {
                    el.setAttribute('aria-label', originalLabel);
                }, 2000);
            } catch (e) { /* Ignore */ }
        },
    },

    filters: {
        formatRuntime(runtime) {
            switch (runtime) {
            case 'php':
                return runtime.toUpperCase();
            case 'pio':
                return 'PlatformIO';
            case 'java':
            case 'node':
            case 'python':
            case 'ruby':
            case 'curl':
            case 'c':
                return runtime.charAt(0).toUpperCase() + runtime.slice(1);
            default:
                return runtime;
            }
        },
    },

    components: {
        VueMarkdown,
        runSvg: () => import('@/assets/img/play.svg'),
        copySvg: () => import('@/assets/img/copy.svg'),
        closeSvg: () => import('@/assets/img/close.svg'),
        Terminal: () => import('@/components/docs/DocsTerminal.vue'),
        CodeResponseSamples: () => import('@/components/docs/CodeResponseSamples.vue'),
    },
};
</script>

<style lang="postcss">
.code-sample-content {
    overscroll-behavior: contain;

    & > div {
        min-height: 100%;
        position: relative;

        & > pre {
            top: 0;
            bottom: 0;
            width: 100%;
            position: absolute;

            & > code {
                min-height: 100%;
                font-size: .875rem;
                padding: 0;
                padding: 1rem;
                background-color: theme('colors.codesample');
                color: theme('colors.gray.100');
            }
        }
    }
}
</style>
